import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./login.css";
import logo from "../logo.png"

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


class Login extends Component {
    constructor(props) {
      super(props);
      this.handleLogin = this.handleLogin.bind(this);
      this.onChangeUsername = this.onChangeUsername.bind(this);
      this.onChangePassword = this.onChangePassword.bind(this);
  
      this.state = {
        username: "",
        password: "",
        loading: false,
        message: ""
      };
    }

    

    onChangeUsername(e) {
        this.setState({
          username: e.target.value
        });
      }
    
      onChangePassword(e) {
        this.setState({
          password: e.target.value
        });
      }
    
      handleLogin(e) {
        e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.login(this.state.username, this.state.password).then(
              () => {
                this.props.router.navigate("/");
                window.location.reload();
              },
              error => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
      
                this.setState({
                  loading: false,
                  message: resMessage
                });
              }
            );
          } else {
            this.setState({
              loading: false
            });
          }
        }

        render() {
            return (
              <div className='container-login position-absolute top-50 start-50 translate-middle row g-3 p-5 bg-light shadow input-group-lg fw-semibold rounded'>
                <div className="col-md-12 text-center">
                  <img src={logo} alt="uniski" width="100" height="100" className="d-inline-block align-text-center mx-2"/>
                </div>
                <h5 class="card-title text-center mb-5 fw-light fs-5">S I N E R G I</h5>
                <Form class="needs-validation" novalidate onSubmit={this.handleLogin} ref={c => { this.form = c; }}>
                
                <div className="form-row">
                  <label htmlFor="username">Username</label>
                  <p />
                    <div class="col-md-12 mb-3">
                      <Input type="text" className="form-control" id="floatingInput" name="username" value={this.state.username} onChange={this.onChangeUsername} validations={[required]} />
                    </div>
                </div>

                <div className="form-row">
                  <label htmlFor="password">Password</label>
                  <p />
                  <div class="col-md-12 mb-3">
                    <Input type="password" className="form-control" id="floatingPassword" name="password" value={this.state.password} onChange={this.onChangePassword} validations={[required]} />
                  </div>
                </div>

                <div className="d-grid text-light">
                  <button className="btn btn-success text-light display-block" disabled={this.state.loading}>
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Login</span>
                  </button>
                </div>

                {this.state.message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {this.state.message}
                    </div>
                  </div>
                )}

                <CheckButton style={{ display: "none" }} ref={c => { this.checkBtn = c; }} />
                </Form>
              </div>
            );
          }
}

export default withRouter(Login);