import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Input() {

    const url = "https://api.sinergi.uniski.ac.id/api/aset/create/pm";
    //const url = "http://localhost:3001/api/aset/create/pm";
    const [values, setValues] = useState({
        namaBarang: '',
        merek: '',
        type: '',
        lokasi: '',
        warna: '',
        bahan: '',
        tanggalBeli: '',
        kondisi: '',
        jumlahBarang: '',
        hargaSatuan: '',
        tahunAnggaran: '',
        spesifikasi: '',
        jenisAset: '',
    })

    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(url, values)
        .then(res => {
            navigate("/", {replace: true})
            console.log(res)
        })
        .catch(err => console.log(err))
    }
    return (
        <div className="container-sm py-5">
            <div className="text-center pb-4">
                <h2>Peralatan dan Mesin</h2>
            </div>
            <form onSubmit={handleSubmit} className="row g-3 p-5 bg-light shadow input-group-lg fw-semibold rounded">
                <div className="col-md-6">
                    <label className="form-label fs-6" for="InputNamaBarang">Nama Barang</label>
                    <input className="form-control mb-4 shadow-sm" type="text" name="InputNamaBarang" required onChange={e => setValues({...values, namaBarang: e.target.value})}></input>
                </div>
                <div className="col-md-6">
                    <label className="form-label fs-6" for="InputMerek">Merek</label>
                    <input className="form-control mb-4 shadow-sm" type="text" name="InputMerek" required onChange={e => setValues({...values, merek: e.target.value})}></input>
                </div>
                <div className="col-md-6">
                    <label className="form-label fs-6" for="InputType">Type</label>
                    <input className="form-control mb-4 shadow-sm" type="text" name="InputType" required onChange={e => setValues({...values, type: e.target.value})}></input>
                </div>
                <div className="col-md-6">
                    <label className="form-label fs-6" for="InputLokasi">Lokasi</label>
                    <select className="form-control mb-4 shadow-sm" required onChange={e => setValues({...values, lokasi: e.target.value})}>
                        <option selected>Pilih Lokasi</option>
                        <option value="Gedung A">Gedung A</option>
                        <option value="Gedung B">Gedung B</option>
                    </select>
                </div>
                <div className="col-md-6">
                    <label className="form-label fs-6" for="InputWarna">Warna</label>
                    <input className="form-control mb-4 shadow-sm" type="text" name="InputWarna" required onChange={e => setValues({...values, warna: e.target.value})}></input>
                </div>
                <div className="col-md-6">
                    <label className="form-label fs-6" for="InputBahan">Bahan</label>
                    <input className="form-control mb-4 shadow-sm" type="text" name="InputWarna" required onChange={e => setValues({...values, bahan: e.target.value})}></input>
                </div>
                <div className="col-md-2">
                    <label className="form-label fs-6" for="InputTanggalPembelian">Tanggal Pembelian</label>
                    <input className="form-control mb-4 shadow-sm" type="date" name="InputTanggalPembelian" required onChange={e => setValues({...values, tanggalBeli: e.target.value})}></input>
                </div>
                
                <div className="col-md-2">
                    <label className="form-label fs-6" for="InputKondisiBarang">Kondisi Barang</label>
                    <select className="form-control mb-4 shadow-sm" required onChange={e => setValues({...values, kondisi: e.target.value})}>
                        <option selected>Kondisi</option>
                        <option value="Baru">Baru</option>
                        <option value="Bekas">Bekas</option>
                    </select>
                </div>

                <div className="col-md-2">
                    <label className="form-label fs-6" for="InputJumlahBarang">Jumlah Barang</label>
                    <div className="input-group flex-nowrap">
                        <input className="form-control mb-4 shadow-sm" aria-describedby="basic-addon1" type="number" min="1" name="InputJumlahBarang" required onChange={e => setValues({...values, jumlahBarang: e.target.value})}></input>
                        <span className="input-group-text mb-4" id="basic-addon1">Unit</span>
                    </div>
                    
                </div>

                <div className="col-md-3">
                    <label className="form-label fs-6" for="InputHargaSatuan">Harga Satuan</label>
                    <div className="input-group flex-nowrap">
                        <span class="input-group-text mb-4" id="basic-addon2">Rp</span>
                        <input className="form-control mb-4 shadow-sm" aria-describedby="basic-addon2" type="number" min="0" placeholder="5000000" name="InputHargaSatuan" required onChange={e => setValues({...values, hargaSatuan: e.target.value})}></input>
                    </div>
                </div>

                <div className="col-md-3">
                    <label className="form-label fs-6" for="InputTahunAnggaran">Tahun Anggaran</label>
                    <input className="form-control mb-4 shadow-sm" type="number" min="0" name="InputTahunAnggaran" required onChange={e => setValues({...values, tahunAnggaran: e.target.value})}></input>
                </div>

                <div className="col-md-10">
                    <label className="form-label fs-6" for="InputSpesifikasi">Spesifikasi</label>
                    <textarea className="form-control mb-4 shadow-sm" type="paragraph" name="InputSpesifikasi" rows="4" required onChange={e => setValues({...values, spesifikasi: e.target.value})}></textarea>
                </div>
                
                <div className="col-md-2">
                    <label className="form-label fs-6" for="InputKondisiBarang">Jenis Aset</label>
                    <select className="form-control mb-4 shadow-sm" required onChange={e => setValues({...values, jenisAset: e.target.value})}>
                        <option selected>Jenis Aset</option>
                        <option value="Pembelian">Pembelian</option>
                        <option value="Bantuan">Bantuan</option>
                    </select>
                </div>

                <div className="col-md-6 row g-2">
                    <button type="Submit" className="btn btn-success">Sumbit</button>
                    <Link to="/" className="btn btn-secondary">Kembali</Link>
                </div>

            </form>
        </div>
    );
}
