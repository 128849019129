import React from "react";

function TableNew({dataSet}) {
    return(
        <div className="table-responsive-sm bg-light border-0 radius mb-3">
                <table  className="table table-striped border">
                    <thead>
                        <tr className="table-primary text-center">
                            <th>
                                No
                            </th>
                            <th>
                                Nama Barang
                            </th>
                            <th>
                                Nomor Inventaris
                            </th>
                            <th>
                                Kategori
                            </th>
                            <th>
                                Kondisi
                            </th>
                            <th>
                                Tahun Anggaran
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {
                            dataSet.map((aset, index) => {
                                if (index<10){
                                    return (
                                        <tr className="border" key={index}>
                                            <td className="border">{index + 1}</td>
                                            <td className="border">{aset.namaBarang}</td>
                                            <td className="border text-center">{aset.nomorInventaris}</td>
                                            <td className="border text-center">{aset.kategori}</td>
                                            <td className="border text-center">{aset.kondisi}</td>
                                            <td className="border text-center">{aset.tahunAnggaran}</td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
    )
}

export default TableNew;