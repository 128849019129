import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { HashRouter, Route, Routes } from 'react-router-dom';
import ErrorPage from './error-page';
import Dasboard from './components/dasboard';
import Login from './components/login.component';
import Register from './components/register.component';
import Profile from './components/profile.component';
import BoardUser from './components/board-user.component';
import BoardModerator from './components/board-moderator.component';
import BoardAdmin from './components/board-admin.component';
import PM from './components/Input-pm';
import KOM from './components/Input-kom';
import AK from './components/Input-ak';
import ELK from './components/Input-elk';
import MBL from './components/Input-mbl';
import KB from './components/Input-kb';
import Nav from './components/Nav';
import Qrcode from './components/qrcode';
import Rekapitulasi from './components/rekapitulasi';
import RincianAset from './components/document/rincianAset';


ReactDOM.createRoot(document.getElementById('root')).render(

  <HashRouter>
      <Routes>
        <Route path='/' element={<Nav />} errorElement={<ErrorPage />}>
          <Route index element={<Dasboard />} />
          <Route path='input' >
            <Route path='input-pm' element={<PM />} />
            <Route path='input-kom' element={<KOM />} />
            <Route path='input-ak' element={<AK />} />
            <Route path='input-elk' element={<ELK />} />
            <Route path='input-mbl' element={<MBL />} />
            <Route path='input-kb' element={<KB />} />
          </Route>
          <Route path='qrcode' element={<Qrcode />} />
          <Route path='rekapitulasi' element={<Rekapitulasi />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/user' element={<BoardUser />} />
        <Route path='/mod' element={<BoardModerator />} />
        <Route path='/admin' element={<BoardAdmin />} />
        <Route path='/rincian/aset/:id' element={<RincianAset />} />
      </Routes>
    </HashRouter>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 