import React, { useRef } from "react";
import QRCode from "react-qr-code";
import logo from "../logo.png";
import kop from "../image/Kop-Foto.jpg"

import { useReactToPrint } from "react-to-print";

const PDFfile = ({dataSet}) => {
    let urlQr = "https://sinergi.uniski.ac.id/#/rincian/aset/";
    //let urlQr = "https://localhost:3000/#/rincian/aset/";
    const pdfRef = useRef();


    const printPDF = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: "Daftar Aset YPIBSK",
    });
/*
    const downloadPDF = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 30;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            pdf.save('Rekapitulasi.pdf');
        });
    }
    */
    const total = [];

    function setSum() {
        let sum = 0;
        for (let i = 0; i < total.length; i++) {
            sum += total[i];
        }
        return(
            <tr className="border border-black">
                <td></td>
                <td></td>
                <td><h5>Jumlah</h5></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="border border-black text-center">Rp.  {sum}</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        )
    }

    return(
        <>
        <div className="container-fluid justify-content-center" style={{width: "1800px"}}>
            <div className="container bg-success-subtle my-4">
                <div className="row">
                <div className="col-md-10 py-3">
                    SILAHKAN PRINT FILE ASET BERIKUT
                </div>
                <div className="col-md-2 py-3">
                    <button className="btn btn-success" onClick={printPDF}>Cetak Aset</button>
                </div>
                </div>
            </div>
            <div ref={pdfRef} className="table-responsive-sm bg-white text-center" style={{ width: "1800px" }}>
                <img src={kop} style={{width: "100%", marginBottom: "40px"}}></img>
                <h5>DAFTAR INVENTARIS ASET</h5>
                <h5>YAYASAN PENDIDIKAN ISLAM BENDE SEGUGUK KAYUAGUNG</h5>
                <br />
                <table  className="table table-striped border border-black" style={{ width: "100%" }}>
                    <thead>
                        <tr className="table-primary border border-black text-center">
                            <th className="border border-black" style={{ width: "3%" }}>
                                No
                            </th>
                            <th className="border border-black" style={{ width: "13%" }}>
                                Nama Barang
                            </th>
                            <th className="border border-black" style={{ width: "13%" }}>
                                Nomor Inventaris
                            </th>
                            <th className="border border-black" style={{ width: "13%" }}>
                                Kategori
                            </th>
                            <th className="border border-black" style={{ width: "6%" }}>
                                Kondisi
                            </th>
                            <th className="border border-black" style={{ width: "6%" }}>
                                Tanggal Pembelian
                            </th>
                            <th className="border border-black" style={{ width: "6%" }}>
                                Jenis Aset
                            </th>
                            <th className="border border-black" style={{ width: "15%" }}>
                                Harga Satuan
                            </th>
                            <th className="border border-black" style={{ width: "6%" }}>
                                Tahun Anggaran
                            </th>
                            <th className="border border-black" style={{ width: "10%" }}>
                                Spesifikasi
                            </th>
                            <th className="border border-black" style={{ width: "20%" }}>
                                QR Code
                            </th>
                        </tr>
                        </thead>
                        {
                            dataSet.map((aset, index) => {
                                total.push(aset.hargaSatuan)
                                    return (
                                        <tr className="border border-black" style={{ height: "100px"}} key={index}>
                                            <td className="border border-black text-center">{aset.id}</td>
                                            <td className="border border-black">{aset.namaBarang}</td>
                                            <td className="border border-black text-center">{aset.nomorInventaris}</td>
                                            <td className="border border-black text-center">{aset.kategori}</td>
                                            <td className="border border-black text-center">{aset.kondisi}</td>
                                            <td className="border border-black text-center">{aset.tanggalBeli}</td>
                                            <td className="border border-black text-center">{aset.jenisAset}</td>
                                            <td className="border border-black text-center">Rp. {aset.hargaSatuan}</td>
                                            <td className="border border-black text-center">{aset.tahunAnggaran}</td>
                                            <td className="border border-black text-center">{aset.spesifikasi}</td>
                                            <td className="border border-black text-center" >
                                                <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }} 
                                                value={urlQr + aset.id}
                                                viewBox={`0 0 256 256`}/>
                                            </td>
                                        </tr>
                                    )
                            })
                        }
                        
                            {
                                setSum()
                            }
                            
                </table>
            </div>
        </div>
        </>
    )
};
export default PDFfile;