import React, { useEffect, useState } from "react";
import axios from "axios";
import OnProcess from "./onProcess";
import PDFfile from "./PDFfile";

function Qrcode() {
        const url = "https://api.sinergi.uniski.ac.id/api/aset";
        //const url = "http://localhost:3001/api/aset";
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState([]);
        
        useEffect(() => {
            const getAset = async() => {
                setLoading(true);
                await axios.get(url)
                .then(res => {
                    setData(res.data)
                    setLoading(false)
                })
                .catch(err => console.log(err));
            }
            getAset();
        },[]);
    
    return (
            <PDFfile dataSet={data}/>
        
    )

}

export default Qrcode;