import React from "react";
import "./footer.css"
import { Link } from "react-router-dom";
import logo from '../logo.png';

function Footer (){
    return (
        <>
        <div className="container-fluid row g-0 border-top ">
            <div className="col-md-4 bg-set text-center">
                <br />
                <h6 className="set-cl">Dibuat Oleh</h6>
                <p className="set-cl">TIM ICT UNISKI</p>
                <br />
                <p className="set-cl set-smaller">All Rights Reserved.</p>
                <br />
            </div>
            <div className="col-md-8 ps-5">
                <br />
                <Link className="navbar-brand fs-6 fw-bold fw-medium text-primary-emphasis text-decoration-none " to='/'>
                    <img src={logo} alt="uniski" width="60" height="60" className="d-inline-block align-text-center mx-2"/>
                        S I N E R G I
                </Link>
                <br />
                <p />
                <a className="ps-3 set-uniski" href="https://uniski.ac.id/">UNISKI Kayuagung</a>
                <p className="set-small ps-3">Cintaraja, Kec. Kayu Agung, Kabupaten Ogan Komering Ilir, Sumatera Selatan</p>
                <br />
                <h6 className="set-smaller ps-3">©2023 envy.Tim Pengemebang IT Universitas Islam Ogan Komering Ilir.</h6>
                <br />
            </div>
        </div>
        </>
    )
}

export default Footer;