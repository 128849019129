import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import QRCode from "react-qr-code";

function RincianAset() {

    let urlQr = "https://sinergi.uniski.ac.id/#/rincian/aset/";
    //let urlQr = "https://localhost:3000/#/rincian/aset/";

        const url = "https://api.sinergi.uniski.ac.id/api/aset/find/";
        //const url = "http://localhost:3001/api/aset/find/";
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState([]);
        
        const {id} = useParams()
        useEffect(() => {
            const getAset = async() => {
                setLoading(true);
                await axios.get(url + id)
                .then(res => {
                    setData(res.data)
                    setLoading(false)
                })
                .catch(err => console.log(err));
            }
            getAset();
        },[]);
        console.log(data);
    return(
        <>
        <div className="container bg-light my-4">
            <div className="row g-3">
                <div className="col-md-12 text-center">
                    <h5 className="my-4">RINCIAN ASET "{data.namaBarang}"</h5>
                </div>
                <div className="col-md-6 border rounded">
                    <img src="#" style={{width: "100%"}} />
                </div>
                <div className="col-md-6">
                        <table style={{width: "100%"}}>
                            <tr>
                                <td><h6>Nama Barang</h6></td>
                                <td>:</td>
                                <td>{data.namaBarang}</td>
                            </tr>
                            <tr>
                                <td><h6>Nomor Inventaris</h6></td>
                                <td>:</td>
                                <td>{data.nomorInventaris}</td>
                            </tr>
                            <tr>
                                <td><h6>Kategori</h6></td>
                                <td>:</td>
                                <td>{data.kategori}</td>
                            </tr>
                            <tr>
                                <td><h6>Kondisi</h6></td>
                                <td>:</td>
                                <td>{data.kondisi}</td>
                            </tr>
                            <tr>
                                <td><h6>Tanggal Beli</h6></td>
                                <td>:</td>
                                <td>{data.tanggalBeli}</td>
                            </tr>
                            <tr>
                                <td><h6>Jenis Aset</h6></td>
                                <td>:</td>
                                <td>{data.jenisAset}</td>
                            </tr>
                            <tr>
                                <td><h6>Jumlah Barang</h6></td>
                                <td>:</td>
                                <td>{data.jumlahBarang}</td>
                            </tr>
                            <tr>
                                <td><h6>Harga Satuan</h6></td>
                                <td>:</td>
                                <td>Rp.  {data.hargaSatuan}</td>
                            </tr> 
                            <tr>
                                <td><h6>Tahun Anggaran</h6></td>
                                <td>:</td>
                                <td>{data.tahunAnggaran}</td>
                            </tr>
                            
                        </table>
                </div>
                <div className="col-md-12 row g-1">
                    <table className="table table-striped border" style={{width: "100%"}}>
                        <thead>
                            <tr className="table-primary text-center border">
                                <th className="text-center border">Merek</th>
                                <th className="text-center border">Type</th>
                                <th className="text-center border">Lokasi</th>
                                <th className="text-center border">Warna</th>
                                <th className="text-center border">Bahan</th>
                                <th className="text-center border">Spesifikasi</th>
                            </tr>
                        </thead>
                            <tr>
                                <td className="text-center border">{data.merek}</td>
                                <td className="text-center border">{data.type}</td>
                                <td className="text-center border">{data.lokasi}</td>
                                <td className="text-center border">{data.warna}</td>
                                <td className="text-center border">{data.bahan}</td>
                                <td className="text-center border">{data.spesifikasi}</td>
                            </tr>
                    </table>
                </div>
                <div className="col-md-12 row g-1">
                    <table className="table table-striped border" style={{width: "100%"}}>
                        <thead>
                            <tr className="table-primary text-center border">
                                <th className="text-center border">Jenis</th>
                                <th className="text-center border">Tahun Pembuatan</th>
                                <th className="text-center border">Isi Silinder</th>
                                <th className="text-center border">Nomor Rangka</th>
                                <th className="text-center border">Nomor Mesin</th>
                                <th className="text-center border">Nomor BPKB</th>
                                <th className="text-center border">Bahan Bakar</th>
                            </tr>
                        </thead>
                            <tr>
                                <td className="text-center border">{data.jenis}</td>
                                <td className="text-center border">{data.tahunPembuatan}</td>
                                <td className="text-center border">{data.isiSilinder}</td>
                                <td className="text-center border">{data.nomorRangka}</td>
                                <td className="text-center border">{data.nomorMesin}</td>
                                <td className="text-center border">{data.nomorBPKB}</td>
                                <td className="text-center border">{data.bahanBakar}</td>
                            </tr>
                    </table>
                </div>
                <div className="col-md-6">

                </div>
                <div className="col-md-6">
                    <table style={{width: "100%"}}>
                        <tr>
                            <td className="text-center border border-1 border-black">
                                <h5>{data.namaBarang}</h5>
                                <h6>{data.nomorInventaris}</h6>
                            </td>
                            <td className="border p-2 border-1 border-black" >
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }} 
                                    value={urlQr + data.id}
                                    viewBox={`0 0 256 256`}/>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        </>
    )
}

export default RincianAset;