import React, { Component } from 'react';
import { Link, Outlet } from 'react-router-dom';
import AuthService from "../services/auth.service";
import logo from '../logo.png';
import './Nav.css';
import Footer from './footer';


import UserService from "../services/user.service";
import Dash from './dash';

class Nav extends Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      allUser: true,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        allUser: false,
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      allUser: true,
    });
    this.props.history.push("/")
    
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard, allUser } = this.state;
  return (
    <>
      <nav className="navbar container-fluid shadow-sm p-1 bg-body-tertiary" >
        <div className='container'>
        <Link className="navbar-brand fs-6 fw-bold fw-medium text-primary-emphasis text-decoration-none " to='/'>
            <img src={logo} alt="uniski" width="60" height="60" className="d-inline-block align-text-center mx-2"/>
            Sistem Inventaris Aset Digital 
        </Link>
        <ul className="nav nav-underline justify-content-end me-4">
          <li className="nav-item">
            <Link className="nav-link fs-6 ms-4 fw-semibold" active aria-current="page" to='/'>Dasboard</Link>
          </li>
          {currentUser && (
          <li className="nav-item">
            <Link className="nav-link fs-6 ms-4 fw-semibold" to='/rekapitulasi'>Rekapitulasi</Link>
          </li>
          )}
          {currentUser && (
          <li className="nav-item">
            <Link className="nav-link fs-6 ms-4 fw-semibold" to='/qrcode'>Cetak Aset</Link>
          </li>
          )}
          <li className="nav-item">
            <div class="dropdown">
              <Link className="nav-link fs-6 ms-4 fw-semibold" type="button" data-bs-toggle="dropdown" aria-expanded="false">Akun <i class="bi bi-person-circle text-success" /></Link>
              <ul class="dropdown-menu drop-nav dropdown-menu-end text-center">
                {currentUser && (
                <li className='py-2'><Link class="link fs-6 fw-semibold" to="#">Profil</Link></li>
                )}
                {showAdminBoard && (
                <li className='py-2'><Link class="link fs-6 fw-semibold" to="#">Setelan</Link></li>
                )}
                
                {currentUser && (
                  <>
                  <li><hr class="dropdown-divider" /></li>
                  <li><Link className="fs-6 fw-semibold text-danger" to='#' onClick={this.logOut}>Keluar <i class="bi bi-box-arrow-right" /></Link></li>
                  </>
                )}
                {allUser && (
                  <>
                  <li><Link className="fs-6 fw-semibold text-success" to="/login">Masuk <i class="bi bi-box-arrow-in-left" /></Link></li>
                  </>
                )}
              </ul>
            </div>
          </li>
        </ul>
        <div class="btn-group toggle-btn me-2">
          <button type="button" class="btn bi bi-justify fs-2 rounded" data-bs-toggle="dropdown" aria-expanded="false">
          </button>
          <ul class="dropdown-menu drop-nav dropdown-menu-end">

            <li className="py-3">
              <Link className="nav-link fs-6 fw-semibold" to='/'>Dasboard</Link>
            </li>
            {currentUser && (
            <li className="py-3">
              <Link className="nav-link fs-6 fw-semibold" to='/rekapitulasi'>Rekapitulasi</Link>
            </li>
            )}
            {currentUser && (
            <li className="py-3">
              <Link className="nav-link fs-6 fw-semibold" to='/qrcode'>QR Code</Link>
            </li>
            )}
            <li><hr class="dropdown-divider" /></li>
            {currentUser && (
            <li className="dropdown-item">
              <Link className="fs-6 fw-semibold">Profil</Link>
            </li>
            )}
            {showAdminBoard && (
            <li className="dropdown-item">
              <Link className="fs-6 fw-semibold">Setelan</Link>
            </li>
            )}
            {currentUser && (
            <li className="dropdown-item">
              <Link className="fs-6 fw-semibold text-danger" to='#' onClick={this.logOut}>Keluar <i class="bi bi-box-arrow-right" /></Link>
            </li>
            )}
            {allUser && (
              <li className='dropdown-item'>
                <Link className='fs-6 fw-semibold text-success' to="/login">Masuk <i class="bi bi-box-arrow-in-left" /></Link>
              </li>
            )}
          </ul>
        </div>
        </div>
      </nav>
      {allUser && (
       <Dash />
       )}
      {showModeratorBoard && (
        <Outlet />
      )}
      <Footer />
    </>
  );
  }
}

export default Nav;
