import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dasboard.css";
import TableNew from "./table/tableNew";
import BarChart from "./chart/barChart";
import { Link } from "react-router-dom";

function Dasboard() {
    const url = "https://api.sinergi.uniski.ac.id/api/aset";
    //const url = "http://localhost:3001/api/aset";
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    
    useEffect(() => {
        const getAset = async() => {
            setLoading(true);
            await axios.get(url)
            .then(res => {
                setData(res.data)
                setLoading(false)
            })
            .catch(err => console.log(err));
        }
        getAset();
    },[]);

    const sortDataUp = data.sort((a, b) => a.tahunAnggaran - b.tahunAnggaran);

    let dataSet = [...new Set(sortDataUp.map((data) => data.tahunAnggaran))]

    let dataLabel = sortDataUp.map((data) => {
        let dataSet = {
            x: data.tahunAnggaran,
            y: data.hargaSatuan
        }
        return dataSet
    })

    let resultData = dataLabel.reduce((acc, {x, y}) => {
        if (!acc[x]) {
            acc[x] = {x, y, count: 1};
        } else {
            acc[x].y += y;
            acc[x].count++;
        }
        return acc;
    }, {})

    const userData = {
        labels: dataSet,
        datasets: [{
            label: "Total Belanja Aset",
            data: Object.values(resultData),
            backgroundColor: "#50ccab",
            borderColor: "black",
            borderWidth: 2,
            borderRadius: 5,
            borderSkipped: false,
        }],
    }

    const userOption = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'SINERGI UNISKI : Grafik Belanja Aset',
            },
        },
    }
    return (
        <div className="container-fluid"> 
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ):(
                <div className="container">
            <div className="row g-3 border p-2 pb-5" id="FormFilter">
            <div className="col-md-12 row g-0">
                <div className="col-md-12 p-5 text-center">
                    <BarChart chartData={userData} chartOption={userOption} />
                </div>

                    <div className="col-md-12 row g-0 p-2 bg-input">
                        
                        <div className="col-md-2 d-grid p-2">
                        <button class="btn btn-success my-auto dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Tambah Aset +
                        </button>
                        <ul class="dropdown-menu text-center py-3 px-4">
                            <li><Link to="/input/input-pm" class="dropdown-item">Peralatan dan Mesin</Link></li>
                            <li><Link to="/input/input-kom" class="dropdown-item">Komputer</Link></li>
                            <li><Link to="/input/input-ak" class="dropdown-item">Aksesoris Komputer</Link></li>
                            <li><Link to="/input/input-elk" class="dropdown-item">Elektronik</Link></li>
                            <li><Link to="/input/input-mbl" class="dropdown-item">Furnitur</Link></li>
                            <li><Link to="/input/input-kb" class="dropdown-item">Kendaraan Bermotor</Link></li>
                        </ul>
                        </div>

                        <div className="col-md-10 p-3">
                            <h6 className="text-secondary-emphasis m-0">"SINERGI UNISKI" Adalah Sistem Inventaris Aset Digital Universitas Islam Ogan Komering Ilir Kayuagung.</h6>
                        </div>
                        
                    </div>
                    <div className="overflow-auto">
                        <TableNew dataSet={data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} />
                    </div>
                    
                </div>
                
            </div>
            </div>
            )}
        
        </div>
    );
}
export default Dasboard;