import React from "react";
import gambar1 from "../image/gambar1.png";
import gambar2 from "../image/gambar2.jpg";

function Dash () {
    return(
        <>
            <div className="container my-5">
                <div className="row g-3">
                    <div className="col-md-6">
                        <img src={gambar1} className="img-fluid"></img>
                    </div>
                    <div className="col-md-6">
                        <h6>Sistem Inventaris Aset Digital</h6>
                        <span>adalah sebuah platform yang dirancang untuk membantu mengelola aset Yayasn Pendidikan Islam Bende Seguguk Kayuagung Di Universitas Islam Ogan Komering Ilir "UNISKI". Sistem ini dilengkapi dengan fitur-fitur seperti pencatatan aset, pengelompokan, pembaruan status, dan pelacakan historis. Anda dapat dengan mudah mencatat detail aset, seperti nomor seri, tanggal pembelian, dan informasi lainnya yang relevan. Selain itu, sistem ini juga memungkinkan Anda untuk mengelompokkan aset berdasarkan kategori, sehingga memudahkan dalam pengelolaan dan pemantauan.
                        Dengan sistem inventaris aset digital, UNISKI dapat mengontrol dan melacak penggunaan aset, termasuk siapa yang bertanggung jawab atas aset tersebut. Ini membantu mencegah kehilangan, kerusakan, atau penyalahgunaan aset yang berharga bagi Yayasan.
                        Dengan menggunakan sistem inventaris aset digital, Yayasan akan memiliki visibilitas yang lebih baik atas aset yang ada di UNISKI, memudahkan pengelolaan dan pemantauan, serta membantu menjaga keamanan dan efisiensi operasional.
                        </span>
                    </div>
                    <div className="col-md-12 text-center">
                        <img src={gambar2} className="img-fluid"></img>
                    </div>
                    <div className="col-md-12">
                        <h6>Beberapa fitur yang akan dimiliki aplikasi sistem inventaris aset digital:</h6>
                        <ol class="list-group list-group-numbered">
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">Pencatatan Aset</div>
                                    Aplikasi ini memungkinkan Anda untuk mencatat semua aset yang dimiliki oleh perusahaan, termasuk deskripsi, nomor seri, tanggal pembelian, dan atribut lainnya yang relevan. Ini memudahkan Anda dalam melacak dan mengidentifikasi setiap aset secara individu.
                                    </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">Pembaruan Status</div>
                                    Anda dapat memperbarui status aset, seperti ketersediaan, lokasi, atau kondisi saat ini. Hal ini memungkinkan Anda untuk memiliki visibilitas yang akurat mengenai status setiap aset.
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">Pelacakan Riwayat</div>
                                    Aplikasi ini mencatat riwayat perubahan aset, seperti pembaruan status, perbaikan, atau pemindahan. Ini membantu Anda melacak pergerakan dan perubahan yang terjadi pada setiap aset dari waktu ke waktu.
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">Pencarian dan Filter</div>
                                    Terdapat fitur pencarian dan filter yang memungkinkan Anda untuk mencari aset berdasarkan kriteria tertentu, seperti nama, kategori, atau departemen terkait. Hal ini memudahkan Anda dalam menemukan aset yang Anda butuhkan dengan cepat.
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">Pengelompokan Aset</div>
                                    Anda dapat mengelompokkan aset berdasarkan kategori, departemen, atau atribut lainnya. Ini membantu dalam pengorganisasian dan pemantauan aset yang serupa atau terkait.
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">Integrasi Barcode atau QRCode</div>
                                    Beberapa aplikasi inventaris aset dapat diintegrasikan dengan teknologi barcode atau QRCode untuk memudahkan dalam pencatatan dan pelacakan aset. Anda dapat dengan mudah memindai kode barcode atau QRCode untuk mengidentifikasi aset secara akurat.
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">Laporan dan Analitik</div>
                                    Aplikasi ini sering dilengkapi dengan fitur laporan dan analitik yang membantu Anda menganalisis penggunaan aset, biaya pemeliharaan, dan tren lainnya. Laporan ini memberikan wawasan yang berharga untuk pengambilan keputusan yang lebih baik terkait aset.
                                </div>
                            </li>
                        </ol>
                    </div>
                    
                </div>

            </div>
        </>
    )
}
export default Dash;