import React, { useState } from "react";
import QRCode from "react-qr-code";
import "./table.css";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";

function TableRekap({dataSet}) {
    let urlQr = "https://api.sinergi.uniski.ac.id/api/aset/find/";
    //let urlQr = "https://localhost:3001/api/aset/find/";

    const columns = [
        {
            name: 'Nama Barang',
            selector: row => row.namaBarang,
            sortable: true,
        },
        {
            name: 'Nomor Inventaris',
            selector: row => row.nomorInventaris,
            sortable: true,
        },
        {
            name: 'Kategori',
            selector: row => row.kategori,
            sortable: true,
        },
        {
            name: 'Kondisi',
            selector: row => row.kondisi,
            sortable: true,
        },
        {
            name: 'Tanggal Pembelian',
            selector: row => row.tanggalBeli,
            sortable: true,
        },
        {
            name: 'Jenis Aset',
            selector: row => row.jenisAset,
            sortable: true,
        },
        {
            name: 'Harga Satuan',
            selector: row => row.hargaSatuan,
            sortable: true,
        },
        {
            name: 'Tahun Anggaran',
            selector: row => row.tahunAnggaran,
            sortable: true,
        },
        {
            name: 'Spesifikasi',
            selector: row => row.spesifikasi,
        },
        {
            name: 'Menu',
            selector: row => row.menu,
            width: "190px",
        },
        
    ];
    
    const data = dataSet.map((aset, index) => {
        return(
            {
                namaBarang: aset.namaBarang,
                nomorInventaris: aset.nomorInventaris,
                kategori: aset.kategori,
                kondisi: aset.kondisi,
                tanggalBeli: aset.tanggalBeli,
                jenisAset: aset.jenisAset,
                hargaSatuan: aset.hargaSatuan,
                tahunAnggaran: aset.tahunAnggaran,
                spesifikasi: aset.spesifikasi,
                menu: <div>
                    <Link className="btn btn-primary m-1" to="#">
                        <i class="bi bi-file-post text-white"></i>
                    </Link>
                    <Link className="btn btn-success m-1" to="#">
                        <i class="bi bi-pencil-square text-white"></i>
                    </Link>
                    <Link className="btn btn-danger m-1" to="#">
                        <i class="bi bi-trash text-white"></i>
                    </Link>
                </div>,
            }
        )
    })

    const [records, setRecords] = useState(data);
    function handlerFilter(event) {
        const newData = data.filter(row => {
            return row.namaBarang.toLowerCase().includes(event.target.value.toLowerCase())
        })
        setRecords(newData)
    }
    return(
        <div className="container table-responsive-sm bg-light m-auto border radius mb-3">
            <div className="text-end p-4">
                <input type="text" placeholder="Pencarian" onChange={handlerFilter}/>
            </div>
            <DataTable
                columns={columns}
                data={records}
                fixedHeader
                pagination
            />
        </div>
    )
}

export default TableRekap;