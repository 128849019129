import axios from "axios";
import TableRekap from "./table/tableRekap";
import React, { useEffect, useState } from "react";
import PDFfile from "./PDFfile";

function Rekapitulasi() {
    const url = "https://api.sinergi.uniski.ac.id/api/aset";
    //const url = "http://localhost:3001/api/aset";
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    
    useEffect(() => {
        const getAset = async() => {
            setLoading(true);
            await axios.get(url)
            .then(res => {
                setData(res.data)
                setLoading(false)
            })
            .catch(err => console.log(err));
        }
        getAset();
    },[]);

    return(
        <>
        <div className="container justify-content-center bg-light">
            <br />
            <TableRekap dataSet={data} />
            <br />
        </div>
        </>
    )
}

export default Rekapitulasi;