import React from "react";

function OnProcess() {
    return(
        <>
        <div className="container text-center">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h4>Masih Dalam Pengerjaan</h4>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
        </>
    )
}

export default OnProcess;